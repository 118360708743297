<template>
  <div class="modal-Hidrologi">
    <button
      class="button h-button is-success is-outlined is-raised"
      @click="isOpen = !isOpen"
      type="is-success"
      outlined
    >
      <i class="fal fa-plus"></i><span>Input Pos Dinas</span>
    </button>

    <div :class="['modal', { 'is-active': isOpen }]">
      <div class="modal-background"></div>

      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">Masukan Data Pos Dinas</p>
          <!-- TODO: Close Modal set isOpen to false -->
          <button
            @click.prevent="closeDinas"
            class="delete"
            aria-label="close"
          ></button>
        </header>
        <section class="modal-card-body">
          <div class="form-layout">
            <div class="form-outer">
              
              <div class="form-body">
                <!--Fieldset-->
                <div class="form-fieldset">
                  <div class="columns is-multiline">
                    <div class="column is-12">
                      <div class="field">
                        <label>Jenis Pos</label>
                        <div class="control">
                          <select
                            @blur="$v.form.jenisPos.$touch()"
                            v-model="form.jenisPos"
                            type="text"
                            class="input"
                            placeholder="Masukan Kategori"
                          >
                            <option disabled>Pilih Pos</option>
                            <option>PDA</option>
                            <option>PCH</option>
                          </select>
                          <div v-if="$v.form.jenisPos.$error">
                            <span
                              v-if="!$v.form.jenisPos.required"
                              class="help is-danger"
                              >Jenis Pos Harus Diisi</span
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="columns is-multiline">
                    <div class="column is-6">
                      <div class="field">
                        <label>Nama DAS</label>
                        <div class="control has-icon">
                          <b-autocomplete
                            v-model="form.namaDas"
                            placeholder="e.g. Cimanuk"
                            :keep-first="keepFirst"
                            :open-on-focus="openOnFocus"
                            :data="filteredDataDas"
                            field="namaDas"
                            @select="
                              (option) =>
                                option !== null
                                  ? (form.dasId = option.id)
                                  : Number()
                            "
                            :clearable="clearable"
                          >
                            <template #empty
                              >No results for {{ form.namaDas }}</template
                            >
                          </b-autocomplete>
                          <div class="form-icon">
                            <i class="lnil lnil-trees-alt-1"></i>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="column is-6">
                      <div class="field">
                        <label>Nama Sungai</label>
                        <div class="control has-icon">
                          <b-autocomplete
                            :data="filteredDataSungai"
                            v-model="form.namaSungai"
                            placeholder="e.g. Cimanuk"
                            @typing="getAsyncData"
                            :loading="isFetching"
                            field="namaSungai"
                            @select="
                              (option) =>
                                option !== null
                                  ? (form.sungaiId = option.id)
                                  : Number()
                            "
                            :clearable="clearable"
                          >
                            <template #empty
                              >No results for {{ form.namaSungai }}</template
                            >
                          </b-autocomplete>
                          <div class="form-icon">
                            <i class="lnil lnil-air-flow"></i>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!--Fieldset-->
                <div class="form-fieldset">
                  <div class="fieldset-heading">
                    <h4>Informasi Pos</h4>
                  </div>

                  <div class="columns is-multiline">
                    <div class="column is-6">
                      <div class="field">
                        <label>Nama Pos</label>
                        <div class="control has-icon">
                          <input
                            @blur="$v.form.namaPos.$touch()"
                            v-model="form.namaPos"
                            type="text"
                            class="input"
                            placeholder="Nama Pos"
                          />

                          <div v-if="$v.form.namaPos.$error">
                            <span
                              v-if="!$v.form.namaPos.required"
                              class="help is-danger"
                              >Nama Pos Harus Diisi</span
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="column is-6">
                      <div class="field">
                        <label>Kode Register</label>
                        <div class="control">
                          <input
                            @blur="$v.form.kodeRegister.$touch()"
                            v-model="form.kodeRegister"
                            type="text"
                            class="input"
                            placeholder="Register"
                          />
                          <div v-if="$v.form.kodeRegister.$error">
                            <span
                              v-if="!$v.form.kodeRegister.required"
                              class="help is-danger"
                              >Register Pos Harus Diisi</span
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="column is-6">
                      <div class="field">
                        <label>Latitude</label>
                        <div class="control">
                          <input
                            @blur="$v.form.lat.$touch()"
                            v-model="form.lat"
                            type="number"
                            class="input"
                            placeholder="Latitude"
                          />
                          <div v-if="$v.form.lat.$error">
                            <span
                              v-if="!$v.form.lat.required"
                              class="help is-danger"
                              >Latitude Harus Diisi</span
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="column is-6">
                      <div class="field">
                        <label>Longitude</label>
                        <div class="control">
                          <input
                            @blur="$v.form.lng.$touch()"
                            v-model="form.lng"
                            type="number"
                            class="input"
                            placeholder="Longitude"
                          />
                          <div v-if="$v.form.lng.$error">
                            <span
                              v-if="!$v.form.lng.required"
                              class="help is-danger"
                              >Longitude Harus Diisi</span
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="column is-6">
                      <div class="field">
                        <label>Elevasi</label>
                        <div class="control">
                          <input
                            @blur="$v.form.elevasi.$touch()"
                            v-model="form.elevasi"
                            type="number"
                            class="input"
                            placeholder="elevasi"
                          />
                          <div v-if="$v.form.elevasi.$error">
                            <span
                              v-if="!$v.form.elevasi.required"
                              class="help is-danger"
                              >elevasi Harus Diisi</span
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="column is-6">
                      <div class="field">
                        <label>Lokasi Pos</label>
                        <div class="control">
                          <textarea
                            @blur="$v.form.lokasiPos.$touch()"
                            v-model="form.lokasiPos"
                            type="text"
                            class="textarea is-normal"
                            placeholder="Lokasi Pos"
                          />
                          <div v-if="$v.form.lokasiPos.$error">
                            <span
                              v-if="!$v.form.lokasiPos.required"
                              class="help is-danger"
                              >lokasi Pos Harus Diisi</span
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!--Fieldset-->
              </div>
            </div>
          </div>
        </section>
        <footer class="modal-card-foot">
          <div class="field is-grouped">
            <div class="control btn1">
              <button
                @click.prevent="sendPosDinas"
                :disabled="isFormInvalid"
                class="button is-normal is-link"
              >
                <span> Simpan </span>
              </button>
            </div>

            <div class="control btn">
              <button
                @click.prevent="closeDinas"
                class="button is-normal is-danger"
              >
                <span> Batal </span>
              </button>
            </div>
          </div>
        </footer>
      </div>
    </div>
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import { mapActions, mapState } from "vuex";
import debounce from "lodash/debounce";
export default {
  props: {
    dinasId: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      sungaiData: [],
      isOpen: false,
      form: {
        namaPos: null,
        kodeRegister: null,
        lat: null,
        lng: null,
        elevasi: null,
        lokasiPos: null,
        dasId: null,
        namaDas: null,
        sungaiId: null,
        namaSungai: null,
        jenisPos: null,
        dinasId: this.dinasId,
      },
      keepFirst: false,
      openOnFocus: false,
      selected: null,
      clearable: false,
      isFetching: false,
    };
  },
  validations: {
    form: {
      namaPos: { required },
      kodeRegister: { required },
      lat: { required },
      lng: { required },
      elevasi: { required },
      lokasiPos: { required },
      jenisPos: { required },
    },
  },
  created() {
    this.fetchSungai();
    this.fetchDas();
  },
  computed: {
    ...mapState({
      sungaiList: (state) => state.sungai.items,
      images: (state) => state.image.items,
      das: (state) => state.das.items,
    }),

    isFormInvalid() {
      return this.$v.form.$invalid;
    },

    filteredDataDas() {
      return this.das.filter((option) => {
        return option.namaDas.toString().indexOf(this.form.namaDas) >= 0;
      });
    },
    filteredDataSungai() {
      return this.sungaiData.filter((option) => {
        return option.namaSungai.toString().indexOf(this.form.namaSungai) >= 0;
      });
    },
  },
  methods: {
    ...mapActions("sungai", ["fetchSungai", "searchDas"]),
    ...mapActions("das", ["fetchDas"]),

    sendPosDinas() {
      this.$store
        .dispatch("posDinas/createPos", this.form)
        .then(() => {
          this.$emit("submitted", this.form);
          this.$toasted.success("Tambah Data Pos Dinas Berhasil", {
            duration: 5000,
          });
          this.isOpen = false;
          this.form.namaPos = null;
          this.form.kodeRegister = null;
          this.form.lat = null;
          this.form.lng = null;
          this.form.elevasi = null;
          this.form.lokasiPos = null;
          this.form.dasId = null;
          this.form.namaDas = null;
          this.form.sungaiId = null;
          this.form.namaSungai = null;
          this.form.jenisPos = null;
        })
        .catch((error) => {
          this.$toasted.error(error.response.data.message, { duration: 3000 });
        });
    },
    closeDinas() {
      this.isOpen = false;

      this.form.namaPos = null;
      this.form.kodeRegister = null;
      this.form.lat = null;
      this.form.lng = null;
      this.form.elevasi = null;
      this.form.lokasiPos = null;
      this.form.dasId = null;
      this.form.namaDas = null;
      this.form.sungaiId = null;
      this.form.namaSungai = null;
      this.form.jenisPos = null;
    },
    getAsyncData: debounce(function (dasId) {
      if (!dasId.length) {
        this.sungaiData = [];
        return;
      }
      this.isFetching = true;
      const params = [`dasId=${this.form.dasId}`].join("&");

      this.searchDas(params)
        .then((response) => {
          this.sungaiData = [];
          response.sungai.forEach((item) => {
            this.isFetching = true;
            this.sungaiData.push(item);
          });
        })
        .catch((error) => {
          this.sungaiData = [];
          throw error;
        })
        .finally(() => {
          this.isFetching = false;
        });
    }, 500),
  },
};
</script>

<style scoped>
.modal {
  animation: swingIn 1.2s ease-in-out;
}

@keyframes swingIn {
  0% {
    transform: scaleY(0.005) scaleX(0);
  }
  50% {
    transform: scaleY(0.005) scaleX(1);
  }
  100% {
    transform: scaleY(1) scaleX(1);
  }
}

.modal-card-title {
  font-size: 20px;
  font-weight: 600;
  color: #2d6efd;
  letter-spacing: 1px;
}
.button {
  font-size: 14px;
  font-weight: 400;
  height: 35px;
  width: 145px;
  letter-spacing: 1px;
  margin-left: 10px;
}

.fal {
  margin-right: 10px;
  margin-left: 10px;
}

@media (max-width: 768px) {
  .modal-card {
    height: 350px;
    width: 350px;
  }

  .button {
    font-size: 14px;
    font-weight: 400;
    height: 35px;
    width: 145px;
    margin-bottom: 5px;
  }
  .is-grouped {
    margin-left: -10px;
  }
}
</style>