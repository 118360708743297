<template>
  <div>
    <SidebarNav />
    <MobileNavbarDash />
    <MobileSidebarDash />
    <SidebarNav />
    <!-- Content Wrapper -->
    <div class="view-wrapper">
      <div class="page-content-wrapper">
        <div class="page-content is-relative">
          <div class="page-title has-text-centered">
            <!-- Sidebar Trigger -->
            <div
              class="huro-hamburger nav-trigger push-resize"
              data-sidebar="layouts-sidebar"
            >
              <span class="menu-toggle has-chevron">
                <span class="icon-box-toggle">
                  <span class="rotate">
                    <i class="icon-line-top"></i>
                    <i class="icon-line-center"></i>
                    <i class="icon-line-bottom"></i>
                  </span>
                </span>
              </span>
            </div>

            <div class="title-wrap">
              <h1 class="title is-4">Data Kuliatas Air</h1>
            </div>
          </div>

          <!--Header-->

          <div class="page-content-inner">
            <div class="map" v-if="!isLoading">
              <div class="cards">
                <KualitasAirMap />
              </div>
            </div>
            <br />

            <div class="list-view-toolbar">
              <div class="control has-icon">
                <input
                  class="input custom-text-filter"
                  placeholder="Search..."
                  v-model="cariSungai"  @keydown="cari"
                 
                />
                <div class="form-icon">
                  <i class="fa fa-search"></i>
                </div>
              </div>

              <div class="tabs-inner">
                <router-link
                  to="/dashboard/inputkualitasair"
                  class="button h-button is-success is-outlined is-raised"
                >
                  <i class="fal fa-plus"></i>
                  <span class="tambah">Tambah Kualitas Air</span>
                </router-link>
              </div>
            </div>

            <div class="page-content-inner">
                  <div class="flex-list-wrapper flex-list-v2">

                    <!--List Empty Search Placeholder -->
                    <div class="page-placeholder custom-text-filter-placeholder is-hidden">
                        <div class="placeholder-content">
                            <img class="light-image" src="../../assets/img/illustrations/placeholders/search-4.svg" alt="" />
                            <img class="dark-image" src="../../assets/img/illustrations/placeholders/search-4-dark.svg"
                                alt="" />
                            <h3>We couldn't find any matching results.</h3>
                            <p class="is-larger">Too bad. Looks like we couldn't find any matching results for the
                                search terms you've entered. Please try different search terms or criteria.</p>
                        </div>
                    </div>

                    <!--Active Tab-->
                    <div id="active-items-tab" class="tab-content is-active" v-if="!isLoading">
                        
                        <div class="flex-table">

                            <!--Table header-->
                            <div class="flex-table-header" data-filter-hide>
                                <span class="is-grow">Nama Sungai</span>
                                <span>Periode</span>
                                <span>Index Pencemaran</span>
                                <span>Mutu Air</span> 
                                <span class="cell-end">Actions</span>
                            </div>
    
                            <div class="flex-list-inner">
                                <!--Table item-->
                                <div class="flex-table-item" v-for="item in data" :key="item.id">
                                    <div class="flex-table-cell is-media is-grow">
                                        
                                        <div>
                                            <span class="item-name dark-inverted" data-filter-match>{{item.sungai}}</span>
                                             
                                        </div>
                                    </div>
                                    <div class="flex-table-cell" data-th="Periode">
                                        <span class="light-text" data-filter-match>{{periode(item.periode) }}</span>
                                    </div>
                                    <div class="flex-table-cell" data-th="Index Pencemaran">
                                        <span class="light-text" data-filter-match>{{item.indexPencemaran}}</span>
                                    </div>
                                    <div class="flex-table-cell" data-th="Mutu Air" v-if="item.hasilAnalisa === 'baku_mutu' ">
                                        <span class="tag is-info is-rounded" data-filter-match>Memenuhi Baku Mutu</span>
                                    </div>
                                    <div class="flex-table-cell" data-th="Mutu Air" v-if="item.hasilAnalisa === 'cemar_ringan' ">
                                        <span class="tag is-success is-rounded" data-filter-match>Cemar Ringan</span>
                                    </div>
                                    <div class="flex-table-cell" data-th="Mutu Air" v-if="item.hasilAnalisa === 'cemar_sedang' ">
                                        <span class="tag is-warning is-rounded" data-filter-match>Cemar Sedang</span>
                                    </div>
                                    <div class="flex-table-cell" data-th="Mutu Air" v-if="item.hasilAnalisa === 'cemar_berat' ">
                                        <span class="tag is-danger is-rounded" data-filter-match>Cemar Berat</span>
                                    </div>
                                    <!-- <div class="flex-table-cell" data-th="Mutu Air">
                                        <span class="tag is-info is-rounded" data-filter-match>{{item.hasilAnalisa}}</span>
                                    </div> -->
                                    
                                    <div class="flex-table-cell cell-end">
                                           <div class="meta-right">
                                        <div class="buttons">
                                        <router-link class="button h-button is-info is-elevated" :to="`/dashboard/kualitasair/${item.id}`">
                                            <span class="icon">
                                                <i aria-hidden="true" class="lnir lnir-scan"></i>
                                            </span>
                                            <span>pratinjau</span>
                                         </router-link>                                          
                                        </div>
                                    </div>
                                    </div>
                                </div> 
                            </div>
    
                        </div>
    
                        <!--Table Pagination-->
                        <nav class="flex-pagination pagination is-rounded" aria-label="pagination">
                              <b-button
                            @click="decrement"
                            type="pagination-previous has-chevron"
                            icon-pack="fas"
                            icon-left="chevron-left"
                            :disabled="hasDisablePreviousPage"
                        ></b-button>
                        <b-button
                            @click="increment"
                            type="pagination-next has-chevron"
                            icon-pack="fas"
                            icon-left="chevron-right"
                            :disabled="hasDisableNextPage"
                        ></b-button>
                            
                        </nav>

                    </div>

                    <!--inactive Tab-->
                    <div id="active-items-tab" class="tab-content is-active" v-if="isLoading">

                        <!--Empty placeholder-->
                        <div class="page-placeholder">
                            <div class="placeholder-content">
                                <img class="light-image is-larger"
                                    src="../../assets/img/illustrations/placeholders/projects.svg" alt="" />
                                <img class="dark-image is-larger"
                                    src="../../assets/img/illustrations/placeholders/projects-dark.svg" alt="" />
                                <h3>Tidak Ada Data.</h3>
                                <p>Tidak ada Data Kualitas Air yang ditampilkan.</p>
                            </div>
                        </div>

                    </div>
                    
                </div>
                <!-- END content iner -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import moment from 'moment'
import { mapActions, mapState } from "vuex";
import SidebarNav from "../dashboardnav/SidebarNav.vue";
import MobileNavbarDash from "../dashboardnav/MobileNavbarDash.vue";
import MobileSidebarDash from "../dashboardnav/MobileSidebarDash.vue";
//import AppSpinner from "../shared/AppSpinner.vue";
import KualitasAirMap from "./KualitasAirMap.vue";
export default {
  name: "KualitasAirList",
  components: {
    SidebarNav,
    MobileNavbarDash,
    MobileSidebarDash,
    //AppSpinner,
    KualitasAirMap,
  },
  data() {
    return {
      status: "",
      cariSungai: "",
      data: [],
      total: 0,
      loading: false,
      sortField: "created",
      sortOrder: "DESC",
      defaultSortOrder: "DESC",
      page: 0,
      perPage: 10,     
      hasDisablePreviousPage: true,
      hasDisableNextPage: false,
      isEmpty: false,
      isBordered: false,
      isStriped: true,
      isNarrowed: false,
      isHoverable: false,
      isFocusable: false,
      isLoading: false,
      hasMobileCards: true,
    };
  },
  created(){
    this.isLoading = true;
    this.fetchKualitasAir()
    .then(() => {
      this.isLoading = false;
    }); 
  },
  beforeMount() {
    const authority = localStorage.getItem("authority");
    if (authority === "DINAS") {
      this.$router.push("/dashboard/posdinas");
    }
  },
  computed: {
    ...mapState({
      results: (state) => state.kualitas.items,
    }),
  },
  methods: {
    ...mapActions("kualitas", ["fetchKualitasAir","searchKualitasAir"]),
    loadAsyncData() {
      const params = [
        `sungai=${this.cariSungai}`,
        `page=${this.page}`,
        `sortType=${this.sortOrder}`,
        `limit=${this.perPage}`,
      ].join("&");
     
      this.searchKualitasAir(params).then(() => {
        let response = this.results;
        this.data = [];
        let enablePreviousPage = response.hasPreviousPage;
        let enableNextPage = response.hasNextPage;
        if (enablePreviousPage) {
           
          this.hasDisablePreviousPage = false;
        } else {
          this.hasDisablePreviousPage = true;
        }

        if (enableNextPage) {
          
          this.hasDisableNextPage = false;
        } else {
          this.hasDisableNextPage = true;
        }

        this.results.data.forEach((item) => {
          this.isLoading = false;
          // item.periode =  moment(String(item.periode)).format('YYYY-MM')
          this.data.push(item);
        });
        // .catch((error) => {
        //   this.data = [];
        //   this.total = 0;
        //   this.loading = false;
        //   throw error;
        // });
      });
    },

     onPageChange(page) {
      this.page = page;
      this.loadAsyncData();
    },
    /*
     * Handle sort event
     */
    onSort(field, order) {
      this.sortField = field;
      this.sortOrder = order;
      this.loadAsyncData();
    },
    increment() {
      this.page += 1;
      this.loadAsyncData();
    },
    decrement() {
      this.page -= 1;
      this.loadAsyncData();
    },
    lokasi() {
      this.fetchKualitasAir();
    },
    cari() {
      this.loadAsyncData();
    },
    dateThAttrs(column) {
      return column.label === "Date"
        ? {
            title: 'This title is sponsored by "th-attrs" prop',
            class: "has-text-success",
          }
        : null;
    },
    columnTdAttrs(row, column) {
      if (row.id === "Total") {
        if (column.label === "ID") {
          return {
            colspan: 4,
            class: "has-text-weight-bold",
            style: {
              "text-align": "left !important",
            },
          };
        } else if (column.label === "Gender") {
          return {
            class: "has-text-weight-semibold",
          };
        } else {
          return {
            style: { display: "none" },
          };
        }
      }
      return null;
    },
    remove(index) {
      console.log("index" + index);
    },
    pratinjau(){
      this.$toasted.error("Maaf Belum Tersedia", { duration: 5000 });
    },
   
    kategori(value){
        if(value === 'baku_mutu'){
          value = 'Memenuhi Baku Mutu'
       }else if(value === 'cemar_ringan'){
          value = 'Cemar Ringan'
       }else if(value === 'cemar_sedang'){
         value = 'Cemar Sedang'
       }else if(value === 'cemar_berat'){
         value = 'Cemar Berat'
       }
       return value;
     },
      periode(value){
      return moment(String(value)).format('YYYY-MM')
     },
  },
   mounted() {
    this.loadAsyncData();
  },
   
};
</script>

<style scoped>
.map {
  min-width: 100%;
  height: 60vh;
}
.lnir-air-flow {
  margin-right: 10px;
  color: #777;
}
.lnir-scan {
  margin-right: 10px;
}
.fa-sharp {
  color: #777;
}
.icon-separator {
  margin-right: 10px;
}

.titles {
  font-size: 14px;
  font-weight: 400;
  margin-left: 5px;
  color: #777;
}
.fa-water {
  color: #777;
  margin-right: 7px;
}
.fal {
  margin-right: 10px;
  font-weight: 400;
}
.tambah {
  font-weight: 400;
}
.lat {
  font-size: 14px;
  font-weight: 400;
  margin-left: 20px;
  color: #777;
}
.long {
  font-size: 14px;
  font-weight: 400;
  margin-left: 15px;
  color: #777;
}

.periode {
  margin-left: 17px;
  font-weight: 400;
  color: #777;
}

@media (max-width: 768px) {
  .fa-water {
    margin-right: 5px;
    color: #777;
  }
  .lat {
    font-size: 12px;
    font-weight: 400;
    margin-left: 20px;
    color: #777;
  }
  .long {
    font-size: 12px;
    font-weight: 400;
    margin-left: 10px;
    color: #777;
  }
  .titles {
    font-size: 12px;
    font-weight: 400;
    color: #777;
    margin-left: 5px;
  }

  .periode {
    font-size: 12px;
    margin-left: 20px;
    font-weight: 400;
    color: #777;
  }
}
</style>