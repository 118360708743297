<template>
  <div>
    <SidebarNav />
    <MobileNavbarDash />
    <MobileSidebarDash />
    <SidebarNav />
    <!-- Content Wrapper -->
    <div
      id="user-profile"
      class="view-wrapper"
      data-naver-offset="214"
      data-menu-item="#layouts-sidebar-menu"
      data-mobile-item="#home-sidebar-menu-mobile"
    >
      <div class="page-content-wrapper">
        <div class="page-content is-relative">
          <div class="page-title has-text-centered">
            <!-- Sidebar Trigger -->
            <div
              class="huro-hamburger nav-trigger push-resize"
              data-sidebar="layouts-sidebar"
            >
              <span class="menu-toggle has-chevron">
                <span class="icon-box-toggle">
                  <span class="rotate">
                    <i class="icon-line-top"></i>
                    <i class="icon-line-center"></i>
                    <i class="icon-line-bottom"></i>
                  </span>
                </span>
              </span>
            </div>

            <div class="title-wrap">
              <h1 class="title is-4">Profile Settings</h1>
            </div>
          </div>

          <div class="page-content-inner">
            <!--Profile Settings-->
            <div class="profile-wrapper">
              <div class="profile-header has-text-centered">
                <div class="h-avatar is-xl">
                  <img
                    class="avatar"
                    src="https://via.placeholder.com/150x150"
                    data-demo-src="assets/img/avatars/photos/8.jpg"
                    alt=""
                    data-user-popover="3"
                  />
                </div>
                <h3 class="title is-4 is-narrow">{{ name }}</h3>

                <div class="profile-stats">
                  <div class="profile-stat">
                    <i class="lnil lnil-users-alt"></i>
                    <span> {{ authorityType }}</span>
                  </div>
                  <div class="separator"></div>
                  <div class="profile-stat">
                    <i class="lnil lnil-postcard"></i>
                    <span>{{ email }} </span>
                  </div>
                </div>
              </div>

              <div class="profile-body">
                <div class="settings-section">
                  <router-link to="/dashboard/csvfile" class="settings-box">
                    <div class="edit-icon">
                      <i class="lnil lnil-pencil"></i>
                    </div>
                    <div class="icon-wrap">
                      <i class="lnil lnil-file-upload"></i>
                    </div>
                    <span>Upload CSV</span>
                    <h3>Manage CSV File</h3>
                  </router-link>
                  <router-link to="/dashboard/users" class="settings-box">
                    <div class="edit-icon">
                      <i class="lnil lnil-pencil"></i>
                    </div>
                    <div class="icon-wrap">
                      <i class="lnil lnil-users"></i>
                    </div>
                    <span>Users</span>
                    <h3>user List</h3>
                  </router-link>
                  <router-link to="/dashboard/pos" class="settings-box">
                    <div class="edit-icon">
                      <i class="lnil lnil-pencil"></i>
                    </div>
                    <div class="icon-wrap">
                      <i class="lnil lnil-house"></i>
                    </div>
                    <span>Pos</span>
                    <h3>Manage Pos</h3>
                  </router-link>
                  <router-link to="/dashboard/device" class="settings-box">
                    <div class="edit-icon">
                      <i class="lnil lnil-pencil"></i>
                    </div>
                    <div class="icon-wrap">
                      <i class="lnil lnil-cogs"></i>
                    </div>
                    <span>DEVICE</span>
                    <h3>Manage Device</h3>
                  </router-link>
                  <router-link
                    to="/dashboard/konteninformasi"
                    class="settings-box"
                  >
                    <div class="edit-icon">
                      <i class="lnil lnil-pencil"></i>
                    </div>
                    <div class="icon-wrap">
                      <i class="lnil lnil-file-name"></i>
                    </div>
                    <span>Content</span>
                    <h3>Manage Content Information</h3>
                  </router-link>

                  <router-link to="/dashboard/sungai" class="settings-box">
                    <div class="edit-icon">
                      <i class="lnil lnil-pencil"></i>
                    </div>
                    <div class="icon-wrap">
                      <i class="lnil lnil-air-flow"></i>
                    </div>
                    <span>Sungai</span>
                    <h3>Manage Sungai</h3>
                  </router-link>
                  <router-link to="/dashboard/das" class="settings-box">
                    <div class="edit-icon">
                      <i class="lnil lnil-pencil"></i>
                    </div>
                    <div class="icon-wrap">
                      <i class="lnil lnil-travel"></i>
                    </div>
                    <span>DAS</span>
                    <h3>Manage Daerah Aliran Sungai</h3>
                  </router-link>
                  <router-link to="/dashboard/kualitasair" class="settings-box">
                    <div class="edit-icon">
                      <i class="lnil lnil-pencil"></i>
                    </div>
                    <div class="icon-wrap">
                      <i class="lnil lnil-water-drops"></i>
                    </div>
                    <span>Air</span>
                    <h3>Manage Kualitas Air</h3>
                  </router-link>

                  <router-link to="/dashboard/forecasting" class="settings-box">
                    <div class="edit-icon">
                      <i class="lnil lnil-pencil"></i>
                    </div>
                    <div class="icon-wrap">
                      <i class="lnil lnil-night-thunder"></i>
                    </div>
                    <span>forecasting</span>
                    <h3>Forecasting List</h3>
                  </router-link>

                  <router-link to="/dashboard/posdinas" class="settings-box">
                    <div class="edit-icon">
                      <i class="lnil lnil-pencil"></i>
                    </div>
                    <div class="icon-wrap">
                      <i class="lnil lnil lnil-tent"></i>
                    </div>
                    <span>Pos Dinas</span>
                    <h3>Pos Pengelolaan Dinas</h3>
                  </router-link>
                  <router-link to="/dashboard/pengumuman" class="settings-box">
                    <div class="edit-icon">
                      <i class="lnil lnil-pencil"></i>
                    </div>
                    <div class="icon-wrap">
                      <i class="lnil lnil-bullhorn"></i>
                    </div>
                    <span>Pengumumnan</span>
                    <h3>Informasi Tentang Pengumuman</h3>
                  </router-link>
                  <router-link to="/dashboard/activity" class="settings-box">
                    <div class="edit-icon">
                      <i class="lnil lnil-pencil"></i>
                    </div>
                    <div class="icon-wrap">
                      <i class="lnil lnil-display"></i>
                    </div>
                    <span>Activity</span>
                    <h3>Informasi Tentang Activity</h3>
                  </router-link>
                  <router-link to="/dashboard/kuesioner" class="settings-box">
                    <div class="edit-icon">
                      <i class="lnil lnil-pencil"></i>
                    </div>
                    <div class="icon-wrap">
                      <i class="lnil lnil-table"></i>
                    </div>
                    <span>Kuesioneri</span>
                    <h3>Informasi Tentang Kuesioneri</h3>
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import SidebarNav from "../dashboardnav/SidebarNav.vue";
import MobileNavbarDash from "../dashboardnav/MobileNavbarDash.vue";
import MobileSidebarDash from "../dashboardnav/MobileSidebarDash.vue";
export default {
  name: "Profile",
  data() {
    return {
      name: "",
      email: "",
      authorityType: "",
    };
  },
  components: {
    SidebarNav,
    MobileNavbarDash,
    MobileSidebarDash,
  },
  created() {
    this.fetchProfile().then((resp) => {
      this.name = resp.name;
      this.email = resp.email;
      this.authorityType = resp.authorityType;
    });
  },
   beforeMount() {
    const authority = localStorage.getItem("authority");
    if (authority === "DINAS") {
      this.$router.push("/dashboard/posdinas");
    }
  },
  computed: {
    isAuthResolved() {
      return this.$store.state.auth.isAuthResolved;
    },
    ...mapState({
      profile: (state) => state.profile.item,
    }),
  },
  methods: {
    ...mapActions("profile", ["fetchProfile"]),
  },
};
</script>
