import axiosInstance from '../../conf/axiosAdmin'; 


export default {
    namespaced: true,
    state: {
        items: [],
        item: {}
    },
    getters: {

    },
    actions: {        
       async fetchPosDinas({state, commit}){            
            commit('setItem', {resource: 'posKedinasan', item: {}}, {root: true})
            return await axiosInstance.get('/api/dinas/view')
            .then(res => {
                const posDinas = res.data   
                     
                commit('setItem', {resource: 'posKedinasan', item: posDinas}, {root: true})
                return state.item
             })
             .catch(()=>{
                  
             });
        },
        updatePos(context, payload){
             
            const headers = {
                'Content-Type': 'application/json',
              }          
                return axiosInstance.put('/api/kedinasan/update', payload,
                {
                  headers: headers
                })             
                 
        },
    },
    mutations: {

    }
}