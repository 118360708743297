<template>
  <div class="modal-Hidrologi">
    <button
      class="button h-button is-success is-outlined is-raised"
      @click="isOpen = !isOpen"
      type="is-success"
      outlined
    >
      <i class="fal fa-plus"></i><span>Input Dinas</span>
    </button>

    <div :class="['modal', { 'is-active': isOpen }]">
      <div class="modal-background"></div>

      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">Masukan Data Pos Dinas</p>
          <!-- TODO: Close Modal set isOpen to false -->
          <button
            @click="isOpen = false"
            class="delete"
            aria-label="close"
          ></button>
        </header>
        <section class="modal-card-body">
          <form>
            <div class="field">
              <label>Nama Dinas Pengelola</label>
              <div class="control has-icon">
                <input
                  @blur="$v.form.dinasPengelola.$touch()"
                  v-model="form.dinasPengelola"
                  type="text"
                  class="input"
                  placeholder="Nama Dinas"
                />

                <div v-if="$v.form.dinasPengelola.$error">
                  <span v-if="!$v.form.dinasPengelola.required" class="help is-danger"
                    >Nama Dinas Harus Diisi</span
                  >
                </div>
              </div>
            </div>
          </form>
        </section>
        <footer class="modal-card-foot">
          <div class="field is-grouped">
            <div class="control btn1">
              <button
               @click.prevent="sendDinas"
                :disabled="isFormInvalid"
                class="button is-normal is-link"
              >
                <span> Simpan </span>
              </button>
            </div>

            <div class="control btn">
              <button
                @click="isOpen = false"
                class="button is-normal is-danger"
              >
                <span> Batal </span>
              </button>
            </div>
          </div>
        </footer>
      </div>
    </div>
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";

export default {
 
  data() {
    return {
      isOpen: false,
      form: {
        dinasPengelola: null,
      },
      selectedFile: null,
    };
  },
  validations: {
    form: {
      dinasPengelola: { required },
    },
  },
  computed: {
    isFormInvalid() {
      return this.$v.form.$invalid;
    },
  },
  methods: {
     sendDinas(){       
         this.$store
        .dispatch("dinas/createDinas", this.form)
        .then((resp) => {
             this.isOpen = false;
         this.$emit('submitted', resp)
          this.$toasted.success("Tambah Dinas Berhasil", {
            duration: 5000,
          });
           
        })
        .catch((error) => {
          this.$toasted.error(error.response.data.message, { duration: 3000 });
        });
        
     }
  },
};
</script>

<style scoped>
.modal {
  animation: swingIn 1.2s ease-in-out;
}

@keyframes swingIn {
  0% {
    transform: scaleY(0.005) scaleX(0);
  }
  50% {
    transform: scaleY(0.005) scaleX(1);
  }
  100% {
    transform: scaleY(1) scaleX(1);
  }
}

.modal-card-title {
  font-size: 20px;
  font-weight: 600;
  color: #2d6efd;
  letter-spacing: 1px;
}
.button {
  font-size: 14px;
  font-weight: 400;
  height: 35px;
  width: 145px;
  letter-spacing: 1px;
  margin-left: 10px;
}

.fal {
  margin-right: 10px;
  margin-left: 10px;
}

@media (max-width: 768px) {
  .modal-card {
    height: 350px;
    width: 350px;
  }

  .button {
    font-size: 14px;
    font-weight: 400;
    height: 35px;
    width: 145px;
    margin-bottom: 5px;
  }
  .is-grouped {
    margin-left: -10px;
  }
}
</style>