import axiosInstance from '../../conf/axiosAdmin'; 


export default {
    namespaced: true,
    state: {
        items: [],
        item: {}
    },
    getters: {

    },
    actions: {
        getDinas({state, commit}){
            commit('setItems', {resource: 'dinas', items: []}, {root: true})
            return axiosInstance.get('/api/dinas/search')
                .then(res => {
                    const dinas = res.data.data
                    commit('setItems', {resource: 'dinas', items: dinas}, {root: true})
                    return state.items
                })
        },
        fetchDinas({state, commit}){
            commit('setItems', {resource: 'dinas', items: []}, {root: true})
            return axiosInstance.get('/api/dinas/search')
                .then(res => {
                    const dinas = res.data 
                    commit('setItems', {resource: 'dinas', items: dinas}, {root: true})
                    return state.items
                })
        },
         createDinas({commit, state}, payload){
           
            return axiosInstance.post('/api/dinas/create', payload)
            .then(res => {
                const createdDinas = res.data
                const index = state.items.length
      
                commit('addItemToArray', {item: createdDinas, index, resource: 'dinas'}, {root: true})
                return createdDinas
              })


        },
        fetchDinasById({state, commit}, dinasId){            
            commit('setItem', {resource: 'dinas', item: {}}, {root: true})
            return axiosInstance.get(`/api/dinas/view/${dinasId}`)
            .then(res => {
                const dinas = res.data
                         
                commit('setItem', {resource: 'dinas', item: dinas}, {root: true})
                return state.item
             })
             .catch(()=>{
                  
             });
        },
        searchDinas({state, commit}, params){           
            commit('setItems', {resource: 'dinas', items: []}, {root: true})
           return axiosInstance.get(`/api/dinas/search?${params}`)
            .then(res => {
                const dinas = res.data                             
                commit('setItems', {resource: 'dinas', items: dinas}, {root: true})
                return state.items
             })
            },
        updateDinas(context, payload){
             
            const headers = {
                'Content-Type': 'application/json',
              }          
                return axiosInstance.put(`/api/dinas/update/${payload.dinasId}`, payload,
                {
                  headers: headers
                })             
                 
        },
        deleteDinasById({state, commit}, dinasId){                            
                commit('setItem', {resource: 'kadasters', item: {}}, {root: true})
                return axiosInstance.delete(`/api/dinas/remove/${dinasId}`)
                .then(res => {
                    const dinas = res.data                 
                    commit('setItem', {resource: 'dinas', item: dinas}, {root: true}) 
                    commit('removeItemFromArray', {resource: 'dinas', registerId: dinas},{root: true}); 
                    return state.item
                 })
                 
            },
        
        
    },
    mutations: {

    }
}