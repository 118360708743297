<template>
  <div>
    <SidebarNav />
    <MobileNavbarDash />
    <MobileSidebarDash />
    <SidebarNav />
    <!-- Content Wrapper -->
    <div
      id="app-project"
      class="view-wrapper"
      data-naver-offset="214"
      data-menu-item="#layouts-sidebar-menu"
      data-mobile-item="#home-sidebar-menu-mobile"
    >
      <div class="page-content-wrapper">
        <div class="page-content is-relative">
          <div class="page-title has-text-centered">
            <!-- Sidebar Trigger -->
            <div
              class="huro-hamburger nav-trigger push-resize"
              data-sidebar="layouts-sidebar"
            >
              <span class="menu-toggle has-chevron">
                <span class="icon-box-toggle">
                  <span class="rotate">
                    <i class="icon-line-top"></i>
                    <i class="icon-line-center"></i>
                    <i class="icon-line-bottom"></i>
                  </span>
                </span>
              </span>
            </div>

            <div class="title-wrap">
              <h1 class="title is-4">Pos Dinas</h1>
            </div>
          </div>

          <div class="page-content-inner project-details">
            <div class="tabs-wrapper is-slider">
              <!-- <div id="project-tab" class="tab-content is-active"> -->
              <div
                class="tab-content"
                v-bind:class="{ 'is-active': isActive == 'Detail' }"
              >
                <div class="columns project-details-inner">
                  <div class="column is-12">
                    <div class="project-details-card">
                      <div class="card-head">
                        <div class="title-wrap">
                          <h3>{{ dinas.dinasPengelola }}</h3>
                        </div>
                         <div class="datatable-toolbar">
                          <div class="buttons">
                            <!-- START -->
                           <router-link
                          class="button h-button is-light is-dark-outlined"
                          :to="`/dashboard/dinas/`"
                        >
                          <span class="icon">
                            <i class="lnir lnir-arrow-left rem-100"></i>
                          </span>
                          <span>Kembali</span>
                        </router-link>
                        <button
                          class="button h-button is-danger is-raised"
                          @click.prevent="deleteDinas"
                        >
                          Hapus
                        </button>

                        <UpdateDinas
                          @submitted="submitted"
                          :dinasId="dinasId"
                        />
                            <!--  END-->
                          </div>
                        </div>
                        
                      </div>

                      <div class="column is-12">
                        <div class="illustration-header-2">
                          <div class="header-meta">
                            <h3>Data Pos Dinas Terkait</h3>
                            <p>
                              Data diinput oleh Administrator Pos Dinas Terkait
                              untuk pengumpulan data hidrologi manual.
                            </p>
                          </div>
                        </div>
                      </div>

                      <div class="project-files">
                        <h4>Recent Pos</h4>
                        <div class="datatable-toolbar">
                          <div class="buttons">
                            <!-- START -->
                            <ModalInputPos
                              :dinasId="dinasId"
                              @submitted="submitted"
                            />
                            <!--  END-->
                          </div>
                        </div>
                        <div class="column is-12 is-multiline">
                          <div v-if="isLoaded" class="list-view-inner">
                            <!--List Item-->
                            <div class="table-wrapper">
                              <b-table
                                class="table is-datatable is-hoverable table-is-bordered"
                                :data="isEmpty ? [] : data"
                                :bordered="isBordered"
                                :striped="isStriped"
                                :narrowed="isNarrowed"
                                :hoverable="isHoverable"
                                :loading="isLoading"
                                :focusable="isFocusable"
                                :mobile-cards="hasMobileCards"
                                paginated
                                backend-pagination
                                :total="total"
                                :per-page="perPage"
                                @page-change="onPageChange"
                                aria-next-label="Next page"
                                aria-previous-label="Previous page"
                                aria-page-label="Page"
                                aria-current-label="Current page"
                                backend-sorting
                                :default-sort-direction="defaultSortOrder"
                                :default-sort="[sortField, sortOrder]"
                                @sort="onSort"
                                icon-pack="fas"
                                icon-left="chevron-right"
                              >
                                <b-table-column
                                  field="namaPos"
                                  label="Nama Pos"
                                  :td-attrs="columnTdAttrs"
                                  v-slot="props"
                                >
                                  {{ props.row.namaPos }}
                                </b-table-column>
                                <b-table-column
                                  field="lokasiPos"
                                  label="Lokasi Pos"
                                  :td-attrs="columnTdAttrs"
                                  v-slot="props"
                                >
                                  {{ props.row.lokasiPos }}
                                </b-table-column>
                                <b-table-column
                                  field="namaDas"
                                  label="nama Das"
                                  :td-attrs="columnTdAttrs"
                                  v-slot="props"
                                >
                                  {{ props.row.namaDas }}
                                </b-table-column>
                                <b-table-column
                                  field="jenisPos"
                                  label="Jenis Pos"
                                  :td-attrs="columnTdAttrs"
                                  v-slot="props"
                                >
                                  {{ props.row.jenisPos }}
                                </b-table-column>
                                <b-table-column
                                  field="id"
                                  label="Action"
                                  v-slot="props"
                                >
                                  <router-link
                                    class="button h-button is-success is-outlined is-raised"
                                    :to="`/dashboard/dinas/${dinasId}/posdinas/${props.row.registerId}`"
                                  >
                                    Pratinjau
                                  </router-link>
                                </b-table-column>

                                <template #empty>
                                  <div class="has-text-centered">
                                    No records
                                  </div>
                                </template>
                              </b-table>
                            </div>

                            <!-- <div v-else class="list-view-inner" > -->
                          </div>
                          <div v-else class="flex-list-inner">
                            <Skeleton />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!--Project Team-->
              <!-- <div id="team-tab" class="tab-content"> -->

              <!-- END Template -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import SidebarNav from "../dashboardnav/SidebarNav.vue";
import MobileNavbarDash from "../dashboardnav/MobileNavbarDash.vue";
import MobileSidebarDash from "../dashboardnav/MobileSidebarDash.vue";
import UpdateDinas from "./UpdateDinas.vue";
import ModalInputPos from "../posDinas/ModalInputPos.vue";
import Skeleton from "../shared/Skeleton.vue"
export default {
  name: "dinasDetail",
  components: {
    SidebarNav,
    MobileNavbarDash,
    MobileSidebarDash,
    UpdateDinas,
    ModalInputPos,
    Skeleton
  },
  data() {
    return {
      isActive: "Detail",
      isLoaded: false,
      data: [],
      total: 0,
      sortField: "created",
      sortOrder: "ASC",
      defaultSortOrder: "ASC",
      page: 0,
      perPage: 10,
      hasDisablePreviousPage: true,
      hasDisableNextPage: false,
      isEmpty: false,
      isBordered: false,
      isStriped: true,
      isNarrowed: false,
      isHoverable: false,
      isFocusable: false,
      isLoading: false,
      hasMobileCards: true,
      dinasId: "",
    };
  },
  created() {
    this.dinasId = this.$route.params.dinasId;
    this.fetchDinasById(this.dinasId).then(() => {
      this.data = [];
      let currentTotal = this.dinas.length;
      if (currentTotal / this.perPage > 1000) {
        currentTotal = this.perPage * 1000;
      }
      this.total = currentTotal;
      this.dinas.dinasList.forEach((item) => {
        this.isLoaded = true;
        this.data.push(item);
      });
    });
  },

  mounted() {},
  computed: {
    ...mapState({
      dinas: (state) => state.dinas.item,
    }),
    getImgUrl() {
      var images = this.image;
      return images;
    },
  },
  methods: {
    ...mapActions("dinas", ["fetchDinasById"]),

    loadAsyncData() {
      this.isLoaded = true;
      this.fetchDinasById(this.dinasId).then(() => {
        this.data = [];
        let currentTotal = this.dinas.length;
        if (currentTotal / this.perPage > 1000) {
          currentTotal = this.perPage * 1000;
        }
        this.total = currentTotal;
        this.dinas.dinasList.forEach((item) => {
          this.isLoaded = true;
          this.data.push(item);
        });
      });
    },

    deleteDinas() {
      this.$swal({
        title: "Apakah anda yakin akan menghapus Data Dinas tersebut?!",
        text: "Mohon pastikan kembali!",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#c0392b",
        confirmButtonText: "YA, HAPUS!",
        cancelButtonText: "Batal!",
      }).then((result) => {
        // <--
        if (result.value) {
          // <-- if confirmed
          this.$router.push("/dashboard/dinas");
          this.$store.dispatch("dinas/deleteDinasById", this.dinasId);
          this.$toasted.success("Hapus Data Dinas Berhasil", {
            duration: 5000,
          });
        }
      });
    },

    /*
     * Handle page-change event
     */
    onPageChange(page) {
      this.page = page;
      this.loadAsyncData();
    },
    /*
     * Handle sort event
     */
    onSort(field, order) {
      this.sortField = field;
      this.sortOrder = order;
      this.loadAsyncData();
    },
    increment() {
      this.page += 1;
      this.loadAsyncData();
    },
    decrement() {
      this.page -= 1;
      this.loadAsyncData();
    },
    cari() {
      this.loadAsyncData();
    },
    dateThAttrs(column) {
      return column.label === "Date"
        ? {
            title: 'This title is sponsored by "th-attrs" prop',
            class: "has-text-success",
          }
        : null;
    },
    columnTdAttrs(row, column) {
      if (row.id === "Total") {
        if (column.label === "ID") {
          return {
            colspan: 4,
            class: "has-text-weight-bold",
            style: {
              "text-align": "left !important",
            },
          };
        } else if (column.label === "Gender") {
          return {
            class: "has-text-weight-semibold",
          };
        } else {
          return {
            style: { display: "none" },
          };
        }
      }
      return null;
    },
    submitted() {
      this.loadAsyncData();
    },
  },
};
</script>
<style scoped>
.chart {
  height: 450px;
  width: 100%;
}

@media (max-width: 868px) {
  .chart {
    height: 200px;
    width: 360px;
  }
}
</style>