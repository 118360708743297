import axiosInstance from '../../conf/axiosAdmin'; 


export default {
    namespaced: true,
    state: {
        items: [],
        item: {}
    },
    getters: {

    },
    actions: {
        getPos({state, commit}){
            commit('setItems', {resource: 'posDinas', items: []}, {root: true})
            return axiosInstance.get('/api/posdinas/manage/search')
                .then(res => {
                    const posDinas = res.data.pos
                    commit('setItems', {resource: 'posDinas', items: posDinas}, {root: true})
                    return state.items
                })
        },
        fetchPos({state, commit}){
            commit('setItems', {resource: 'posDinas', items: []}, {root: true})
            return axiosInstance.get('/api/posdinas/manage/search')
                .then(res => {
                    const posDinas = res.data.pos 
                    commit('setItems', {resource: 'posDinas', items: posDinas}, {root: true})
                    return state.items
                })
        },
         createPos({commit, state}, payload){
           
            return axiosInstance.post('/api/posdinas/manage/create', payload)
            .then(res => {
                const createdPosDinas = res.data
                const index = state.items.length
      
                commit('addItemToArray', {item: createdPosDinas, index, resource: 'posDinas'}, {root: true})
                return createdPosDinas
              })


        },
        fetchPosById({state, commit}, registerId){            
            commit('setItem', {resource: 'posDinas', item: {}}, {root: true})
            return axiosInstance.get(`/api/posdinas/manage/view/${registerId}`)
            .then(res => {
                const posDinas = res.data           
                commit('setItem', {resource: 'posDinas', item: posDinas}, {root: true})
                return state.item
             })
             .catch(()=>{
                  
             });
        },
        searchPos({state, commit}, params){           
            commit('setItems', {resource: 'posDinas', items: []}, {root: true})
           return axiosInstance.get(`/api/posdinas/manage/search?${params}`)
            .then(res => {
                const posDinas = res.data                             
                commit('setItems', {resource: 'posDinas', items: posDinas}, {root: true})
                return state.items
             })
            },
        updatePos(context, payload){   
             

            const headers = {
                'Content-Type': 'application/json',
              }          
                return axiosInstance.put(`/api/posdinas/manage/update/${payload.registerId}`, payload,
                {
                  headers: headers
                })             
                 
        },
        deletePosById({state, commit}, id){                            
                commit('setItem', {resource: 'posDinas', item: {}}, {root: true})
                return axiosInstance.delete(`/api/posdinas/manage/remove/${id}`)
                .then(res => {
                    const posDinas = res.data                 
                    commit('setItem', {resource: 'posDinas', item: posDinas}, {root: true}) 
                    commit('removeItemFromArray', {resource: 'posDinas', registerId: id},{root: true}); 
                    return state.item
                 })
                 
            },
        
        
    },
    mutations: {

    }
}