import { render, staticRenderFns } from "./DinasList.vue?vue&type=template&id=9c07a964&scoped=true&"
import script from "./DinasList.vue?vue&type=script&lang=js&"
export * from "./DinasList.vue?vue&type=script&lang=js&"
import style0 from "./DinasList.vue?vue&type=style&index=0&id=9c07a964&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9c07a964",
  null
  
)

export default component.exports